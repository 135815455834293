import { useRef, useEffect, useCallback } from 'react'

export default function useIsMounted() {
    const ref = useRef(true)

    useEffect(
        () => () => {
            ref.current = false
        },
        [],
    )

    return useCallback(() => ref.current, [])
}
